import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { httpRequests } from './helpers/HttpRequests'

// TODO: Pusher config
window.Pusher = Pusher;

// * Broadcast private channel
window.Echo = new Echo({
    broadcaster: 'pusher',
    cluster: "us2",
    encrypted: true,
    key: '348a43d2aeb330721e75',
    forceTLS: false, // * False in dev true in production
    disableStats: true,
    authorizer: (channel, options) => {
        const { postRequest } = httpRequests()

        return {
            authorize: (socketId, callback) => {
                postRequest('/broadcasting/auth', { socket_id: socketId, channel_name: channel.name })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
});

const pinia = createPinia()

createApp(App).use(router).use(pinia).mount('#app')
